.pointer,.search_sort{cursor:pointer;}
.strong{font-weight:bold!important;}
input:-webkit-autofill { -webkit-box-shadow:0 0 0 500px white inset !important; }
.card-panel-custom{padding-top:5px!important;padding-bottom:5px!important;line-height: 25px;box-shadow:0 0 1px 0 rgba(0,0,0,0.9) !important;margin:0 0!important;}
.childMargin5{margin-top:5px;}
.modal{background:none!important;box-shadow:none!important;}
.modal-content{background:#fff!important;}
.alert{margin-bottom:0;text-align:center;font-weight:bold;}
.btn{font-size: 1em;}
.img-mobile{max-width: 100%;}
.title-shadow{text-shadow:0 1px 1px #999;}
button.nostyle:active,.nostyle{background:none;border:none;padding:0;margin:0;}
.blue.lighten-1 {
    background-color: #a60042 !important;
}
.blue {
    background-color: #a60042 !important;
}
.lighten-1 {
    background-color: #a60042 !important;
}
a, nav ul a, input:not([type]), input[type="text"], input[type="password"], input[type="email"], input[type="url"], input[type="time"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="tel"], input[type="number"], input[type="search"], textarea.materialize-textarea {font-size:14px !important;}
a:focus, a:hover {color:#b38e47; text-decoration:none;}
body {margin-top:0;}
.container {
    max-width: 1200px;
}
*::-moz-placeholder {
    color: #666;
}